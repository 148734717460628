export default {
    "shop.Info.235900-0": "Зургийг хадгалах",
    "shop.Info.235900-1": "Зургийг өөрчлөх",
    "shop.Info.235900-2": "Дэлгүүрийн нэр",
    "shop.Info.235900-3": "Дэлгүүрийн ID",
    "shop.Info.235900-4": "холбогдох дугаар",
    "shop.Info.235900-5": "Өөрчлөх",
    "shop.Info.235900-6": "Хаяг",
    "shop.Info.235900-7": "Хуулийн этгээдийн нэр",
    "shop.Info.235900-8": "Хуулийн этгээдийн гэрчилгээ",
    "shop.Info.235900-9": "бизнесийн лиценз",
    "shop.Info.235900-10": "Иргэний үнэмлэхний урд",
    "shop.Info.235900-11": "иргэний үнэмлэхний ар тал",
    "shop.Info.235900-12": "иргэний үнэмлэхтэй",
    "shop.Info.235900-13": "хаяг солих",
    "shop.Info.235900-14": "муж",
    "shop.Info.235900-15": "хот",
    "shop.Info.235900-16": "дүүрэг",
    "shop.Info.235900-17": "Дэлгэрэнгүй хаягаа оруулна уу",
    "shop.Info.235900-18": "Цуцлах",
    "shop.Info.235900-19": "Мэдээж",
    "shop.Info.235900-20": "Гар утасны дугаараа солих",
    "shop.Info.235900-21": "Утасны дугаараа оруулна уу",
    "shop.Info.235900-22": "Баталгаажуулах код",
    "shop.Info.235900-23": "зураг байршуулах",
    "shop.Info.235900-24": "Файлуудыг энд чирнэ үү, эсвэл",
    "shop.Info.235900-25": "Байршуулахын тулд товшино уу",
    "shop.Info.235900-26": "Зөвхөн jpg файлыг байршуулах боломжтой/png файл, 500кб-аас хэтрэхгүй",
    "shop.Info.235900-27": "Гар утасны дугаар 11 оронтой байх ёстой",
    "shop.Info.235900-28": "баталгаажуулах код авах",
    "shop.Info.235900-29": "баталгаажуулах код оруулна уу",
    "shop.Info.235900-30": "Өгөгдлийг бүрэн бөглөнө үү",
    "shop.Info.235900-31": "Хаягийг амжилттай өөрчилсөн",
    "shop.Info.235900-32": "Хаягийг өөрчилж чадсангүй",
    "shop.Info.235900-33": "Хэдэн секундын дараа дахин оролдоно уу",
    "goods.Publish.440664-0": "бүтээгдэхүүний зураг",
    "goods.Publish.440664-1": "Та 5 хүртэлх зураг байршуулах боломжтой бөгөөд эхнийх нь бүтээгдэхүүний өнгөц зураг бөгөөд энэ нь жагсаалтад харагдах болно.",
    "goods.Publish.440664-2": "Бүтээгдэхүүний нэр",
    "goods.Publish.440664-3": "Бүтээгдэхүүний нэрийг оруулна уу",
    "goods.Publish.440664-4": "Ангилал",
    "goods.Publish.440664-5": "Талбайг сонгоно уу",
    "goods.Publish.440664-6": "Энгийн бараа",
    "goods.Publish.440664-7": "*1-р бүсийн бүтээгдэхүүний хувьд платформ нь 15-ын төлбөр авах болно%Барилгын хураамж, хэрэглэгчид худалдан авалт хийхдээ 5 дахин их бэлгийн гэрчилгээ авах боломжтой。",
    "goods.Publish.440664-8": "Бүтээгдэхүүний үзүүлэлтүүд",
    "goods.Publish.440664-9": "Шинэ техникийн үзүүлэлтүүд",
    "goods.Publish.440664-10": "Тодорхойлолтын нэрийг оруулна уу（Жишээлбэл：Өнгө, хэмжээ, жин гэх мэт.）",
    "goods.Publish.440664-11": "Тодорхойлолтын утгыг оруулна уу（Жишээлбэл：Улаан, S хэмжээтэй, 5 фунт гэх мэт.）",
    "goods.Publish.440664-12": "Тодорхойлолтын шинэ утгыг нэмнэ үү",
    "goods.Publish.440664-13": "Тодорхойлолтын хуудас үүсгэх",
    "goods.Publish.440664-14": "Тодорхойлолт",
    "goods.Publish.440664-15": "Үнэ",
    "goods.Publish.440664-16": "зах зээлийн үнэ",
    "goods.Publish.440664-17": "нөөцөд байна",
    "goods.Publish.440664-18": "Хүргэлтийн арга",
    "goods.Publish.440664-19": "логистик",
    "goods.Publish.440664-20": "Татаж авах",
    "goods.Publish.440664-21": "Хүргэлт",
    "goods.Publish.440664-22": "Борлуулалтын төлөв",
    "goods.Publish.440664-23": "Албан ёсоор худалдаанд гарлаа",
    "goods.Publish.440664-24": "Одоогоор зарагдаагүй байна",
    "goods.Publish.440664-25": "бүтээгдэхүүний дэлгэрэнгүй",
    "goods.Publish.440664-26": "Бүтээгдэхүүний дэлгэрэнгүй мэдээллийг оруулна уу",
    "goods.Publish.440664-27": "суллах",
    "goods.Publish.440664-28": "дахин тохируулах",
    "goods.Publish.440664-29": "өнгө",
    "goods.Publish.440664-30": "хэмжээ",
    "goods.Publish.440664-31": "нэгжийн үнэ",
    "goods.Publish.440664-32": "оруулна уу",
    "goods.Publish.440664-33": "Бүтээгдэхүүний нэр хоосон байж болохгүй",
    "goods.Publish.440664-34": "сонгоно уу",
    "goods.Publish.440664-35": "Таван хүртэл зураг нэмнэ үү",
    "goods.Publish.440664-36": "Аватар зургийг зөвхөн JPG хэлбэрээр байршуулах боломжтой/PNG Формат!",
    "goods.Publish.440664-37": "Байршуулсан аватар зургийн хэмжээ 2МБ-аас хэтрэхгүй!",
    "goods.Publish.440664-38": "Зөвхөн 5 хүртэлх зураг байршуулах боломжтой",
    "goods.Publish.440664-39": "Хамгийн ихдээ хоёр тодорхойлолтын нэрийг тохируулах боломжтой",
    "goods.Publish.440664-40": "Тодорхойлолтын нэр хоосон байж болохгүй, маягтыг дахин үүсгэнэ үү",
    "goods.Publish.440664-41": "Тодорхойлолтын үнийн форматын алдаа",
    "goods.Publish.440664-42": "Бараа материал нь зөвхөн тоо байж болно",
    "goods.Publish.440664-43": "зах зээлийн үнийн алдаа",
    "goods.Publish.440664-44": "Бүтээгдэхүүний техникийн тодорхойлолтын хуудсыг үүсгэнэ үү!",
    "goods.Publish.440664-45": "Санал хүсэлтийн дагуу агуулгыг зөв бөглөнө үү!",
    "goods.Publish.440664-46": "Бүтээгдэхүүний нэр",
    "goods.List.516095-0": "Бүтээгдэхүүний байдал",
    "goods.List.516095-1": "Бүтээгдэхүүний статусыг сонгоно уу",
    "goods.List.516095-2": "Борлуулалтын төлөв",
    "goods.List.516095-3": "Борлуулалтын статусыг сонгоно уу",
    "goods.List.516095-4": "Бүтээгдэхүүний нэр",
    "goods.List.516095-5": "Бүтээгдэхүүний нэрийг оруулна уу",
    "goods.List.516095-6": "бүтээх хугацаа",
    "goods.List.516095-7": "руу",
    "goods.List.516095-8": "эхлэх өдөр",
    "goods.List.516095-9": "дуусах огноо",
    "goods.List.516095-10": "Бүтээгдэхүүний ангилал",
    "goods.List.516095-11": "сонгоно уу",
    "goods.List.516095-12": "хайх",
    "goods.List.516095-13": "дахин тохируулах",
    "goods.List.516095-14": "серийн дугаар",
    "goods.List.516095-15": "Бүтээгдэхүүний ID",
    "goods.List.516095-16": "бүтээгдэхүүний зураг",
    "goods.List.516095-17": "Ангилал",
    "goods.List.516095-18": "Энгийн бараа",
    "goods.List.516095-19": "Хуваалцсан бараа",
    "goods.List.516095-20": "нөөцөд байна",
    "goods.List.516095-21": "Аль хэдийн худалдаанд гарсан байна",
    "goods.List.516095-22": "Борлуулалтгүй",
    "goods.List.516095-23": "Цагийг шинэчлэх",
    "goods.List.516095-24": "Борлуулалтын хэмжээ",
    "goods.List.516095-25": "Хамгийн сүүлд татгалзсан шалтгаан",
    "goods.List.516095-26": "ажиллуулах",
    "goods.List.516095-27": "Хүргэлтийн арга",
    "goods.List.516095-28": "Тавиур дээр",
    "goods.List.516095-29": "засварлах",
    "goods.List.516095-30": "устгах",
    "goods.List.516095-31": "Шүүмж харах",
    "goods.List.516095-32": "Шалгах",
    "goods.List.516095-33": "Тавиураас хассан",
    "goods.List.516095-34": "Бараа материалыг тохируулах",
    "goods.List.516095-35": "Хяналт хүлээгдэж байна",
    "goods.List.516095-36": "дахин засварлах",
    "goods.List.516095-37": "Бүтээгдэхүүнийг устгах",
    "goods.List.516095-38": "Хямдралтай",
    "goods.List.516095-39": "Борлуулахаар зогсоосон",
    "goods.List.516095-40": "Тодорхойлолт",
    "goods.List.516095-41": "Өөрчлөлтийг баталгаажуулна уу",
    "goods.List.516095-42": "Цуцлах",
    "goods.List.516095-43": "Бүтээгдэхүүний тоймыг үзэх",
    "goods.List.516095-44": "Бүтээгдэхүүний нэр：",
    "goods.List.516095-45": "Сэтгэгдлийн ID",
    "goods.List.516095-46": "сэтгэгдэл",
    "goods.List.516095-47": "Зургийг хянан үзэх",
    "goods.List.516095-48": "Оддын үнэлгээг хянана уу",
    "goods.List.516095-49": "Хэрэглэгчийн хочийг шалгана уу",
    "goods.List.516095-50": "Бүтээгдэхүүний мэдээлэл",
    "goods.List.516095-51": "Нэр",
    "goods.List.516095-52": "Ангилал",
    "goods.List.516095-53": "Тусгай бүс",
    "goods.List.516095-54": "1-р бүсийн бүтээгдэхүүн",
    "goods.List.516095-55": "Үнэ",
    "goods.List.516095-56": "зах зээлийн үнэ",
    "goods.List.516095-57": "Хүргэлт",
    "goods.List.516095-58": "логистик",
    "goods.List.516095-59": "Татаж авах",
    "goods.List.516095-60": "Албан ёсоор худалдаанд гарлаа",
    "goods.List.516095-61": "Одоогоор зарагдаагүй байна",
    "goods.List.516095-62": "Дэлгэрэнгүй мэдээлэл",
    "goods.List.516095-63": "Нэг товшилтоор жагсаалт",
    "goods.List.516095-64": "Нэг товшилтоор устгах",
    "goods.List.516095-65": "Хүргэлтийн аргыг сонгоно уу",
    "goods.List.516095-66": "Тээвэрлэлтийн аргыг сонгох ёстой!",
    "goods.List.516095-67": "Мэдээж",
    "goods.List.516095-68": "бүх бүтээгдэхүүн",
    "goods.List.516095-69": "бүтэлгүйтсэн",
    "goods.List.516095-70": "Шалтгаан тайлбар",
    "goods.List.516095-71": "Шүүхийн шийдвэрийн хэмжээ",
    "goods.List.516095-72": "Юань",
    "goods.List.516095-73": "Тайлбар",
    "order.ArrList.924216-0": "Төлбөрийн төлөв",
    "order.ArrList.924216-1": "Төлбөрийн төлөвийг сонгоно уу",
    "order.ArrList.924216-2": "захиалгын дугаар",
    "order.ArrList.924216-3": "Захиалгын дугаараа оруулна уу",
    "order.ArrList.924216-4": "Захиалгын статус",
    "order.ArrList.924216-5": "Захиалгын статусыг сонгоно уу",
    "order.ArrList.924216-6": "Хүлээн авагчийн нэр",
    "order.ArrList.924216-7": "Хүлээн авагчийн нэрийг оруулна уу",
    "order.ArrList.924216-8": "Хүлээн авагчийн утасны дугаар",
    "order.ArrList.924216-9": "Хүлээн авагчийн утасны дугаарыг оруулна уу",
    "order.ArrList.924216-10": "Бүтээгдэхүүний нэр",
    "order.ArrList.924216-11": "Бүтээгдэхүүний нэрийг оруулна уу",
    "order.ArrList.924216-12": "захиалгын хугацаа",
    "order.ArrList.924216-13": "руу",
    "order.ArrList.924216-14": "эхлэх өдөр",
    "order.ArrList.924216-15": "дуусах огноо",
    "order.ArrList.924216-16": "Хүргэлтийн хугацааг баталгаажуулна уу",
    "order.ArrList.924216-17": "Хүргэлтийн хугацаа",
    "order.ArrList.924216-18": "Захиалгын төрөл",
    "order.ArrList.924216-19": "Захиалгын төрлийг сонгоно уу",
    "order.ArrList.924216-20": "Захиалга авна",
    "order.ArrList.924216-21": "Логистикийн захиалга",
    "order.ArrList.924216-22": "Захиалгын ангилал",
    "order.ArrList.924216-23": "Захиалгын ангиллыг сонгоно уу",
    "order.ArrList.924216-24": "Хуваалцсан захиалга",
    "order.ArrList.924216-25": "Ердийн захиалга",
    "order.ArrList.924216-26": "хайх",
    "order.ArrList.924216-27": "дахин тохируулах",
    "order.ArrList.924216-28": "EXCEL-г экспортлох",
    "order.ArrList.924216-29": "Гишүүн ID",
    "order.ArrList.924216-30": "тоо хэмжээ",
    "order.ArrList.924216-31": "нэгжийн үнэ",
    "order.ArrList.924216-32": "Дунд нийлбэр",
    "order.ArrList.924216-33": "Хүлээн авагчийн гар утасны дугаар",
    "order.ArrList.924216-34": "Хүргэлтийн хаяг",
    "order.ArrList.924216-35": "Нийт захиалга",
    "order.ArrList.924216-36": "Хөдөөгийн сэргээн босголтын худалдааны эрхийн бичгийн хасалт",
    "order.ArrList.924216-37": "Нэг дүүргийн нэг бүтээгдэхүүний худалдааны эрхийн бичгийн хасалт",
    "order.ArrList.924216-38": "бодит төлбөр",
    "order.ArrList.924216-39": "Платформын хасалтын оноо",
    "order.ArrList.924216-40": "Бизнесийн ашиг",
    "order.ArrList.924216-41": "Офлайнаар төлсөн",
    "order.ArrList.924216-42": "Борлуулалтын дараах үйлчилгээнд бүртгүүлнэ үү",
    "order.ArrList.924216-43": "төлбөрийн нөхцөл",
    "order.ArrList.924216-44": "Тайлбар",
    "order.ArrList.924216-45": "Авах гэж байна",
    "order.ArrList.924216-46": "Бэлэн мөнгөний тооцоо хийх хугацаа",
    "order.ArrList.924216-47": "Худалдааны эрхийн бичгийн төлбөрийн хугацаа",
    "order.ArrList.924216-48": "ажиллуулах",
    "order.ArrList.924216-49": "Өөрөө авах бүтээгдэхүүний хувьд гар утсаараа захиалгаа баталгаажуулна уу",
    "order.ArrList.924216-50": "Шалгах",
    "order.ArrList.924216-51": "логистикийг шалгах",
    "order.ArrList.924216-52": "Усан онгоц",
    "order.ArrList.924216-53": "Хүлээн авагчийн мэдээллийг өөрчлөх",
    "order.ArrList.924216-54": "Логистикийн компанийн мэдээллийг бөглөсний дараа",
    "order.ArrList.924216-55": "Логистикийн хяналтын дугаар нэмнэ үү",
    "order.ArrList.924216-56": "Логистикийн компанийн нэр",
    "order.ArrList.924216-57": "сонгоно уу",
    "order.ArrList.924216-58": "ачааны дугаар",
    "order.ArrList.924216-59": "Логистик захиалгын дугаар хоосон байж болохгүй",
    "order.ArrList.924216-60": "устгах",
    "order.ArrList.924216-61": "Цуцлах",
    "order.ArrList.924216-62": "Мэдээж",
    "order.ArrList.924216-63": "Логистикийг өөрчлөх",
    "order.ArrList.924216-64": "Логистикийн захиалгын дугаарыг оруулна уу",
    "order.ArrList.924216-65": "дэлгэрэнгүй мэдээллийг шалгана уу",
    "order.ArrList.924216-66": "Хүлээн авагчийн мэдээллийг өөрчлөх",
    "order.ArrList.924216-67": "хүлээн авагч：",
    "order.ArrList.924216-68": "холбогдох дугаар：",
    "order.ArrList.924216-69": "муж/хот/дүүрэг：",
    "order.ArrList.924216-70": "Аймгаа сонгоно уу",
    "order.ArrList.924216-71": "Хот сонгоно уу",
    "order.ArrList.924216-72": "Дүүргээ сонгоно уу",
    "order.ArrList.924216-73": "Хаяг：",
    "order.ArrList.924216-74": "Цуцлах",
    "order.ArrList.924216-75": "батлах",
    "order.ArrList.924216-76": "Логистикийн компаниа сонгоно уу",
    "order.ArrList.924216-77": "Амжилттай хуулсан",
    "order.ArrList.924216-78": "Логистикийн хяналтын дугаарыг нэмнэ үү",
    "order.RestockLists.182807-0": "захиалгын дугаар:",
    "order.RestockLists.182807-1": "Захиалгын дугаараа оруулна уу",
    "order.RestockLists.182807-2": "Бүтээгдэхүүний нэр:",
    "order.RestockLists.182807-3": "Бүтээгдэхүүний нэрийг оруулна уу",
    "order.RestockLists.182807-4": "Захиалгын статус：",
    "order.RestockLists.182807-5": "Захиалгын статусыг сонгоно уу",
    "order.RestockLists.182807-6": "Төлбөр төлөх",
    "order.RestockLists.182807-7": "хүргэгдэнэ",
    "order.RestockLists.182807-8": "Орохыг хүлээж байна",
    "order.RestockLists.182807-9": "Агуулахад аль хэдийн байрлуулсан",
    "order.RestockLists.182807-10": "огнооны сонголт",
    "order.RestockLists.182807-11": "Огноо сонгоно уу",
    "order.RestockLists.182807-12": "тэр өдөр",
    "order.RestockLists.182807-13": "сүүлийн долоо хоног",
    "order.RestockLists.182807-14": "бараг сар",
    "order.RestockLists.182807-15": "сүүлийн 3 сар",
    "order.RestockLists.182807-16": "бараг хагас жил",
    "order.RestockLists.182807-17": "хайх",
    "order.RestockLists.182807-18": "сэргээх",
    "order.RestockLists.182807-19": "Экспорт Excel",
    "order.RestockLists.182807-20": "захиалгын дугаар",
    "order.RestockLists.182807-21": "захиалгын хэмжээ",
    "order.RestockLists.182807-22": "нэгжийн үнэ",
    "order.RestockLists.182807-23": "Бүтээгдэхүүний нэр",
    "order.RestockLists.182807-24": "Тодорхойлолт",
    "order.RestockLists.182807-25": "тоо хэмжээ",
    "order.RestockLists.182807-26": "Захиалгын статус",
    "order.RestockLists.182807-27": "захиалгын хугацаа",
    "order.RestockLists.182807-28": "Логистикийн компани",
    "order.RestockLists.182807-29": "Замын хуудасны дугаар",
    "order.RestockLists.182807-30": "Хүргэлтийн хугацааг баталгаажуулна уу",
    "order.RestockLists.182807-31": "ажиллуулах",
    "order.RestockLists.182807-32": "Шалгах",
    "order.RestockLists.182807-33": "Оролтоо баталгаажуулна уу",
    "order.RestockLists.182807-34": "дэлгэрэнгүй мэдээллийг шалгана уу",
    "order.RestockLists.182807-35": "Үндсэн мэдээлэл",
    "order.RestockLists.182807-36": "Захиалгын статус:",
    "order.RestockLists.182807-37": "захиалгын хугацаа:",
    "order.RestockLists.182807-38": "Логистикийн мэдээлэл:",
    "order.RestockLists.182807-39": "логистикийг шалгах",
    "order.RestockLists.182807-40": "Бүтээгдэхүүний мэдээлэл",
    "order.RestockLists.182807-41": "Бүтээгдэхүүний ID",
    "order.RestockLists.182807-42": "Үнэ",
    "order.RestockLists.182807-43": "Дунд нийлбэр",
    "order.RestockLists.182807-44": "Логистикийн мэдээлэл",
    "order.RestockLists.182807-45": "Логистикийн компани:",
    "order.RestockLists.182807-46": "Замын хуудасны дугаар:",
    "order.RestockLists.182807-47": "Логистикийн байдал:",
    "order.RestockLists.182807-48": "Логистикийн дэлгэрэнгүй мэдээлэл:",
    "order.RestockLists.182807-49": "Үйлдвэрлэгчийн захиалгыг авч чадсангүй",
    "order.RestockLists.182807-50": "Энэ бүтээгдэхүүнийг агуулахад оруулах эсэх?",
    "order.RestockLists.182807-51": "Мэдээж",
    "order.RestockLists.182807-52": "Цуцлах",
    "order.RestockLists.182807-53": "Оруулсан амжилттай!",
    "order.RestockLists.182807-54": "Агуулахыг цуцалсан",
    "order.Refund.396382-0": "захиалгын дугаар",
    "order.Refund.396382-1": "Захиалгын дугаараа оруулна уу",
    "order.Refund.396382-2": "Бүтээгдэхүүний нэр",
    "order.Refund.396382-3": "Бүтээгдэхүүний нэрийг оруулна уу",
    "order.Refund.396382-4": "муж",
    "order.Refund.396382-5": "Статус сонгоно уу",
    "order.Refund.396382-6": "холбогдох дугаар",
    "order.Refund.396382-7": "Утасны дугаараа бичнэ үү",
    "order.Refund.396382-8": "Захиалгын төрөл",
    "order.Refund.396382-9": "Захиалгын статусыг сонгоно уу",
    "order.Refund.396382-10": "Захиалга авна",
    "order.Refund.396382-11": "Логистикийн захиалга",
    "order.Refund.396382-12": "Захиалгын ангилал",
    "order.Refund.396382-13": "Захиалгын ангиллыг сонгоно уу",
    "order.Refund.396382-14": "Ердийн захиалга",
    "order.Refund.396382-15": "Хуваалцсан захиалга",
    "order.Refund.396382-16": "хайх",
    "order.Refund.396382-17": "дахин тохируулах",
    "order.Refund.396382-18": "Гишүүн ID",
    "order.Refund.396382-19": "Буцаан олголтын хэмжээ",
    "order.Refund.396382-20": "буцах шалтгаан",
    "order.Refund.396382-21": "Буцаан олголтын хугацаа",
    "order.Refund.396382-22": "Хянаагүй",
    "order.Refund.396382-23": "зөвшөөрч байна",
    "order.Refund.396382-24": "санал нийлэхгүй байна",
    "order.Refund.396382-25": "ажиллуулах",
    "order.Refund.396382-26": "Шалгах",
    "order.Refund.396382-27": "Шүүмж",
    "order.Refund.396382-28": "Буцаан олголтын хяналт",
    "order.Refund.396382-29": "Худалдааны үнэлгээний сэтгэгдэл",
    "order.Refund.396382-30": "Худалдааны шүүмжийн сэтгэгдлийг оруулна уу",
    "order.Refund.396382-31": "Аудитын үр дүн",
    "order.Refund.396382-32": "сонгоно уу",
    "order.Refund.396382-33": "Цуцлах",
    "order.Refund.396382-34": "Мэдээж",
    "order.Refund.396382-35": "дэлгэрэнгүй мэдээллийг шалгана уу",
    "order.Refund.396382-36": "Хяналтын үр дүнг сонгоно уу",
    "order.Details.493022-0": "Үндсэн мэдээлэл",
    "order.Details.493022-1": "захиалгын дугаар：",
    "order.Details.493022-2": "Захиалгын статус：",
    "order.Details.493022-3": "Буцаан олголт",
    "order.Details.493022-4": "Буцаан олголт амжилтгүй боллоо",
    "order.Details.493022-5": "Буцаан олголт амжилттай боллоо",
    "order.Details.493022-6": "захиалгын хугацаа：",
    "order.Details.493022-7": "Хоч нэр：",
    "order.Details.493022-8": "Төлбөрийн хугацаа：",
    "order.Details.493022-9": "төлбөрийн нөхцөл：",
    "order.Details.493022-10": "Хүлээн авагчийн нэр：",
    "order.Details.493022-11": "Хүлээн авагчийн холбоо барих дугаар：",
    "order.Details.493022-12": "Хүргэлтийн хаяг：",
    "order.Details.493022-13": "Худалдаачид буцаан олголтыг буцаан олгохоос татгалзсан шалтгаанууд:",
    "order.Details.493022-14": "Логистикийн мэдээлэл：",
    "order.Details.493022-15": "Логистикийг засварлах",
    "order.Details.493022-16": "Бүтээгдэхүүний мэдээлэл",
    "order.Details.493022-17": "Бүтээгдэхүүний ID",
    "order.Details.493022-18": "Бүтээгдэхүүний нэр",
    "order.Details.493022-19": "Бүс нутгийн ангилал",
    "order.Details.493022-20": "Үнэ",
    "order.Details.493022-21": "тоо хэмжээ",
    "order.Details.493022-22": "Тодорхойлолт",
    "order.Details.493022-23": "Дунд нийлбэр",
    "order.Details.493022-24": "Худалдан авагчийн мессеж",
    "order.Details.493022-25": "бүтээгдэхүүний зураг",
    "order.Details.493022-26": "Нэр",
    "order.Details.493022-27": "Ангилал",
    "order.Details.493022-28": "Тусгай бүс",
    "order.Details.493022-29": "1-р бүсийн бүтээгдэхүүн",
    "order.Details.493022-30": "Энгийн бараа",
    "order.Details.493022-31": "нөөцөд байна",
    "order.Details.493022-32": "Хүргэлт",
    "order.Details.493022-33": "логистик",
    "order.Details.493022-34": "Татаж авах",
    "order.Details.493022-35": "Дэлгэрэнгүй мэдээлэл",
    "order.Details.493022-36": "Логистикийн компанийн мэдээллийг бөглөсний дараа",
    "order.Details.493022-37": "Логистикийн хяналтын дугаар нэмнэ үү",
    "order.Details.493022-38": "Логистикийн компанийн нэр",
    "order.Details.493022-39": "сонгоно уу",
    "order.Details.493022-40": "ачааны дугаар",
    "order.Details.493022-41": "Логистик захиалгын дугаар хоосон байж болохгүй",
    "order.Details.493022-42": "устгах",
    "order.Details.493022-43": "Цуцлах",
    "order.Details.493022-44": "Мэдээж",
    "order.Details.493022-45": "Логистикийн компаниа сонгоно уу",
    "order.Details.493022-46": "Логистикийн хяналтын дугаарыг нэмнэ үү",
    "order.Details.493022-47": "Амжилттай өөрчилсөн",
    "finance.Report.576422-0": "Хүчинтэй захиалгын нийт тоо",
    "finance.Report.576422-1": "нэг",
    "finance.Report.576422-2": "Нийт үр дүнтэй захиалгын дүн",
    "finance.Report.576422-3": "Юань",
    "finance.Report.576422-4": "Төлбөр тооцоо хийх нийт дүн",
    "finance.Report.576422-5": "Тогтоосон нийт дүн",
    "finance.Report.576422-6": "Статистик",
    "finance.Report.576422-7": "Өнөөдрийн нийт захиалгын тоо",
    "finance.Report.576422-8": "Өнөөдрийн нийт захиалга",
    "finance.Report.576422-9": "Энэ сарын нийт захиалга",
    "finance.Report.576422-10": "Энэ сарын нийт захиалга",
    "finance.Withdraw.623086-0": "Боломжтой үлдэгдэл：",
    "finance.Withdraw.623086-1": "Үлдэгдэл төлбөрийг түр хугацаанд авах боломжгүй：",
    "finance.Withdraw.623086-2": "Татан авалтын дүнг оруулна уу：",
    "finance.Withdraw.623086-3": "Хэмжээг оруулна уу:",
    "finance.Withdraw.623086-4": "Татаж авахаа баталгаажуулна уу",
    "finance.Withdraw.623086-5": "1.Татгалзах ажлын цаг",
    "finance.Withdraw.623086-6": "өдөр бүр",
    "finance.Withdraw.623086-7": "2.Шилжүүлэх заавар：Төлбөрийн ирэх цаг нь T+1，Ажлын бус өдрүүдийг ажлын өдөр болгон хойшлуулдаг",
    "finance.Withdraw.623086-8": "Татгалзах асуудлаар зөвлөгөө өгөх утас：028-8768-3701 Хэрэглэгчдэд үйлчлэх цаг：8:30-17:30",
    "finance.Withdraw.623086-9": "Татан авалтын дүн хоосон байж болохгүй!",
    "finance.Withdraw.623086-10": "Татгалзах өргөдлийг баталгаажуулна уу？",
    "finance.Withdraw.623086-11": "татах",
    "finance.Withdraw.623086-12": "Мэдээж",
    "finance.Withdraw.623086-13": "Цуцлах",
    "views.Login.730424-0": "Худалдааны арын удирдлагын төвөөс хай",
    "views.Login.730424-1": "данс：",
    "views.Login.730424-2": "Хэрэглэгчийн нэрийг оруулна уу",
    "views.Login.730424-3": "нууц үг：",
    "views.Login.730424-4": "Нууц үгээ оруулна уу",
    "views.Login.730424-5": "Нэвтрэх",
    "views.Login.730424-6": "дахин тохируулах",
    "views.Login.730424-7": "Мэдээллийг зааврын дагуу зөв бөглөнө үү",
    "views.Manage.792054-0": "дэлгүүрийн удирдлага",
    "views.Manage.792054-1": "Дэлгүүрийн мэдээлэл",
    "views.Manage.792054-2": "Бүтээгдэхүүний менежмент",
    "views.Manage.792054-3": "Бүтээгдэхүүн нийтлэх",
    "views.Manage.792054-4": "Бүтээгдэхүүний жагсаалт",
    "views.Manage.792054-5": "Захиалгын удирдлага",
    "views.Manage.792054-6": "Бүх захиалга",
    "views.Manage.792054-7": "Буцаан олголтын захиалга",
    "views.Manage.792054-8": "Худалдан авах захиалга",
    "views.Manage.792054-9": "Санхүүгийн менежмент",
    "views.Manage.792054-10": "Бизнесийн санхүүгийн тайлан",
    "views.Manage.792054-11": "татах",
    "part.MainHeader.912422-0": "болих",
    "part.MainHeader.912422-1": "дэлгүүрийн удирдлага",
    "part.MainHeader.912422-2": "Дэлгүүрийн мэдээлэл",
    "part.MainHeader.912422-3": "Амжилттай гарлаа!",
    "part.MainHeader.680378-0": "Хятад Хонконг",
    "part.MainHeader.680378-1": "бусад бүс нутаг",
    "part.MainHeader.680378-2": "Монгол",
    "assets.dict.697759-0": "Тавиурнаас буулгаж байна",
    "assets.dict.697759-1": "Үүнийг нэмсэн",
    "assets.dict.697759-2": "Хяналт хүлээгдэж байна",
    "assets.dict.697759-3": "зөвшөөрөөгүй",
    "assets.dict.697759-4": "Бүс №1",
    "assets.dict.697759-5": "Энгийн бараа",
    "assets.dict.697759-6": "Үнэгүй хүргэлт байхгүй",
    "assets.dict.697759-7": "Хүргэлт үнэгүй",
    "assets.dict.697759-8": "Төлбөр амжилтгүй боллоо",
    "assets.dict.697759-9": "төлбөр амжилттай",
    "assets.dict.697759-10": "Төлбөр төлөх",
    "assets.dict.697759-11": "хүргэгдэнэ",
    "assets.dict.697759-12": "Хүлээн авахыг хүлээж байна/Ачаалагдсан",
    "assets.dict.697759-13": "дууссан/Үнэлэгдээгүй",
    "assets.dict.697759-14": "үнэлгээ өгсөн",
    "assets.dict.697759-15": "Буцаан олголт амжилттай боллоо",
    "assets.dict.697759-16": "Буцаан олголтыг хүсэх",
    "assets.dict.697759-17": "WeChat",
    "assets.dict.697759-18": "Алипай",
    "assets.dict.697759-19": "худалдааны ваучер",
    "assets.dict.697759-20": "Алипай+худалдааны ваучер",
    "assets.dict.697759-21": "WeChat+худалдааны ваучер",
    "assets.dict.697759-22": "Барааны төлбөр",
    "assets.dict.697759-23": "Авах гэж байна",
    "assets.dict.697759-24": "Хянаагүй",
    "assets.dict.697759-25": "зөвшөөрч байна",
    "assets.dict.697759-26": "санал нийлэхгүй байна",
    "assets.dict.697759-27": "Устгасан",
    "assets.dict.697759-28": "Тун удахгүй",
    "assets.dict.697759-29": "Тавиур дээр тавих",
    "assets.dict.697759-30": "Орохыг хүлээж байна",
    "assets.dict.697759-31": "Аль хэдийн агуулахад байрлуулсан",
    "assets.dict.697759-32": "Аль хэдийн худалдаанд гарсан байна",
    "assets.dict.697759-33": "Борлуулалтгүй",
    "assets.dict.697759-34": "бичлэг байхгүй",
    "assets.dict.697759-35": "Цуглуулна",
    "assets.dict.697759-36": "дамжин өнгөрөх",
    "assets.dict.697759-37": "Хүргэлт явагдаж байна",
    "assets.dict.697759-38": "Хүлээн авсан",
    "assets.dict.697759-39": "Визээс татгалзах",
    "assets.dict.697759-40": "Хэцүү байдал",
    "assets.dict.697759-41": "буцах",
    "assets.dict.697759-42": "Цуцлах",
    "assets.dict.697759-43": "шилжүүлэх захиалга",
    "assets.dict.697759-44": "PaymentAsia-Алипай",
	"assets.dict.697759-45": "PaymentAsia-WeChat",
    "router.index.400456-0": "Нэвтрэх",
    "router.index.400456-1": "Дэлгүүрийн мэдээлэл",
    "router.index.400456-2": "Бүтээгдэхүүн нийтлэх",
    "router.index.400456-3": "Бүтээгдэхүүний жагсаалт",
    "router.index.400456-4": "Бүх захиалга",
    "router.index.400456-5": "Буцаан олголтын захиалга",
    "router.index.400456-6": "Худалдан авах захиалга",
    "router.index.400456-7": "Бизнесийн санхүүгийн тайлан",
    "router.index.400456-8": "татах",
    "router.index.400456-9": "Энэ хуудас байхгүй байна",
    "router.index.400456-10": "хандалт{0}Энэ хуудсанд зөвшөөрөл шаардлагатай тул эхлээд нэвтэрнэ үү!",
    "pinAn.uniformTradeOrder.865777-101": "3-рд 3ахидалын төлбөртэй код"

}
