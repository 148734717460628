/**
 * 此处放置文件（上传、下载等）相关api
 */
import http from "@/assets/http.js";
export default {
    /**
     * 文件上传（可单文件、也可多文件上传）
     * @files    文件
     */
    uploadImgs(data) {
        return http({
            url: "/api/common/fileInfo/newUpload", //多文件上传
            //  url: '/api/common/fileInfo/uploadFile',
            method: "post",
            data,
        });
    },
    exportEXCEL(data,url) {
        return http({
            url: url, 
            method: "post",
            data,
            responseType: 'blob',
        });
    },
};