import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    // strict: process.env.NODE_ENV === 'development',   //生产环境不要启用严格模式，否则会引起性能损失
    state: {
        isLogin: false, //是否登录
        user: {}, //用户信息
        menus: [], //管理控制台的菜单信息
        currMenuPaths: [], //选中的菜单路径
        pageSize: [10, 20, 30, 40, 50],
        messageKey: 'MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCJX1Mglkr1E6/7T0yYVDLnGH+u0t2COmRNstpkexZP4DYUL/veAcArvz1H5ccOfbAJZCdAAeKmJc/HOfyRmzQ71IjW2O4T8oh4Xpyo1KuLOMID+6j3wpe6JBi5B23PtiOI0esq79c3sxmJgq1x92OiDkuJqKpaO7pGZBkWQTAR6LOcLcnTE7X3O8kND5D25MxFnwAiovanPyS9ZKz4oZQgutqYGCTcMPnp8poQ5dBdGXlIkJBagPxdQkvR/SirZg4lw2JEKj0h20dAmt4pWlc6+EHErtpPHaL1VGDm49Vck8zBVKLZgMSGKNR0UL1jdiFuYZCoy0Esjty/OAjMKk+xAgMBAAECggEAUVuxgdVBI5C7Yx1+AaGsXA24YTPlmKE4koPgsC3t8vSZUq9wffWPeNlUU+aUU9LAlW9Jh7EWKMywGBARs7K15tFjEct8hTfY92EhayQ5TMUasv0y/zmyXr3OHpd1w9TdisS4gL93OTfLYhOQ4bRgv7OzrR3Fdpd1JQdXjESjUZcaYOnAfcGwM81kg32l5oGqXpyr4Nj0eYZCsQyDcYh/+Fs2z3pAOQbRhjlW5S0CU8bnkdtwlotzWDOEgo+/BPoIZ3YTAOA+o2c0cP5r0Mn5MVl+4ZeOd8zCsVb7o5TjhZNxQtMQA30QX2BY/nVM1/gQocXleeZxfjs5z7vPKLy7uQKBgQDp/N2OvoHeXcMcjaC8zV3DY6VZWJkO0rWuv2UyAq6OpYxigudsj10RBXdx1U1NauNObnDaBpwVKjFZ6djO/z875CMXwynLXSwRBws5ScaG7zrj2GKL0LH3bXIBIWVGMZre1cMDbexJX6A4rnflelWs1SWPONs/7fA/Xk7Z05+hTwKBgQCWS6rsc4tnup+ShMY18mr4rqRGf0rWNCeOFW4+LP8HUoFoOPUmm+bSeVLuGJ0Q0xlL3Uh4RtxvZmnckCRyBLx6hovrvvBhficN29UhJu32POvqtGQn395S8Efpsa2Gia2hA6P3TxtBb71UuF0g5uQ4il1RcVYYTFjH3F55FpC+/wKBgQCaIz1AXzbl6RYypQmR1idCI041QSvym9ObPfHHtASRa3TDIECbPwWrxNIeZsj5isQaUp58wYXoW1cPSADOu05IgcysRqAtmz8AKIe4QWVKViWRGmYwfFYADFThccJpLxw6Vs6UX8EbxKajMCtDPAdcRzzq0CV+Q5vT4qhGziWF+QKBgBVk5grMk86LOUXREqoBPQrzST0jx5JgUDUghdXh79ep/fPJD9SUIrJZRN3RsSIA+rva3wMICz7sVCtqdVWpqO7EEkzXnD40h/ioD1mtwffoSijZztwCORKTU5lHioz5MjcWXpPItfeFoWza5Z7WXyxRPeFnAbtpusWQb/5KoQYfAoGAdf1MOsLT9++vWPPKaj5/YqJTf72WC5bLbOOQyX/Ipn35RueKnjx/h8V2081gslw6lnTApRMSb3T3K6A2/CTedvdqaTLpv4IZpkCee3kDcUu8vFvQbciHlh/Fg5+0GgNeax2L/uk079VRwmjcioLTUFW5VJHoR9TSoTXMY+upLpo=',
        dateRangeOpts: { //datePicker 面板快捷项
            shortcuts: [{
                text: '最近一周',
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                }
            }, {
                text: '最近一个月',
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                }
            }, {
                text: '最近三个月',
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                }
            }]
        }
    },
    mutations: {
        // 封装函数统一处理mutations
        //示例：使用vuex.commit('save', {path:'a.b.c', data:val})，结果state.a.b.c = '我是需要保存的数据'
        save(state, { path, data }) {
            if (!path) {
                throw new Error('need path')
            }
            let keyPath = path.split('.')
            let needSave = state
            for (let i = 0; i < keyPath.length - 1; i++) {
                needSave = needSave[keyPath[i]]
                if (!needSave) {
                    throw new Error(`error path: ${keyPath[i]}`)
                }
            }
            needSave[keyPath[keyPath.length - 1]] = data
        },
        //存储登录成功之后的信息
        storeLoginInfo(state, user) {
            state.isLogin = true;
            state.user = user;
        },
        //初始化当前选中菜单的路径
        initCurrMenuPaths(state, paths) {
            state.currMenuPaths = paths;
        }
    },
    actions: {},
    modules: {}
})